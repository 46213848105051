import React from 'react';
import OrderContext from './context/OrderContext';

export default function withOrderContext(Component) {
  return function WrapperComponent(props) {
    return (
      <OrderContext.Consumer>
        {state => <Component {...props} orderContext={state} />}
      </OrderContext.Consumer>
    );
  };
}
