import moment from 'moment-timezone';

export const dateFormat = 'D M YY';

export function getSortedSlots(slots) {
  const newSlots = [...slots];
  return newSlots.sort((slotA, slotB) => new Date(slotA.begin) - new Date(slotB.begin));
}

export function getGroupedSlots(slots) {
  const sortedSlots = getSortedSlots(slots);
  let groupedSlots = [];
  groupedSlots = sortedSlots.reduce((accSlots, slot) => {
    if (accSlots.length === 0) {
      return [{ id: accSlots.length, text: slot.text, begin: slot.begin, end: slot.end }];
    }
    const newAccSlots = [...accSlots];
    if (!accSlots[accSlots.length - 1].text &&
      new Date(slot.begin).getTime() === new Date(accSlots[accSlots.length - 1].end).getTime() &&
      !slot.text
    ) {
      newAccSlots[accSlots.length - 1].end = slot.end;
    } else {
      newAccSlots.push({ id: newAccSlots.length, text: slot.text, begin: slot.begin, end: slot.end });
    }
    return newAccSlots;
  }, []);
  return groupedSlots;
}

export function getGroupedSlotsPerDay(slots) {
  const groupedSlots = getGroupedSlots(slots);
  let groupedSlotsPerDay = [];
  groupedSlotsPerDay = groupedSlots.reduce((accSlots, slot) => {
    if (accSlots.length === 0) return [{ id: 0, slots: [{ text: slot.text, begin: slot.begin, end: slot.end }] }];
    const newAccSlots = [...accSlots];
    if (new Date(slot.begin).toDateString() === new Date(accSlots[accSlots.length - 1].slots[0].begin).toDateString()) {
      newAccSlots[accSlots.length - 1].slots.push({ text: slot.text, begin: slot.begin, end: slot.end });
    } else {
      newAccSlots.push({ id: newAccSlots.length, slots: [{ text: slot.text, begin: slot.begin, end: slot.end }] });
    }
    return newAccSlots;
  }, []);
  return groupedSlotsPerDay;
}

export function getActiveSlots(selectedDates) {
  const activeSlots = [];
  Object.values(selectedDates).forEach((selectedDate) => {
    if (selectedDate.allDay.isActive) {
      const { hours, minutes } = selectedDate.slotBlocks[0].slots[0];
      const begin = moment(selectedDate.date).set({ hours, minutes, seconds: 0, milliseconds: 0 }).format();

      const lastSlotBlock = selectedDate.slotBlocks[selectedDate.slotBlocks.length - 1];
      const slotsLength = lastSlotBlock.slots.length - 1;
      const { hoursEnd, minutesEnd } = lastSlotBlock.slots[slotsLength];
      const end = moment(selectedDate.date).set({
        hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
      }).format();

      activeSlots.push({
        id: activeSlots.length,
        date: selectedDate.date,
        text: selectedDate.allDay.text,
        begin,
        end,
      });
    } else {
      selectedDate.slotBlocks.forEach((slotBlock) => {
        if (slotBlock.isActive) {
          const { hours, minutes } = slotBlock.slots[0];
          const begin = moment(selectedDate.date).set({ hours, minutes, seconds: 0, milliseconds: 0 }).format();

          const slotsLength = slotBlock.slots.length - 1;
          const { hoursEnd, minutesEnd } = slotBlock.slots[slotsLength];
          const end = moment(selectedDate.date).set({
            hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0,
          }).format();

          activeSlots.push({ id: activeSlots.length, date: selectedDate.date, text: slotBlock.text, begin, end });
        } else {
          slotBlock.slots.filter(slot => slot.isActive).forEach((slot) => {
            const { hours, minutes } = slot;
            const begin = moment(selectedDate.date).set({ hours, minutes, seconds: 0, milliseconds: 0 }).format();

            const { hoursEnd, minutesEnd } = slot;
            const end = moment(selectedDate.date).set({
              hours: hoursEnd, minutes: minutesEnd, seconds: 0, milliseconds: 0 }).format();
            activeSlots.push({ id: activeSlots.length, date: selectedDate.date, text: undefined, begin, end,
            });
          });
        }
      });
    }
  });
  return activeSlots;
}

export function getActiveSlotDates(selectedDates) {
  const activeSlots = getActiveSlots(selectedDates);
  const activeSlotDates = activeSlots.reduce((acc, activeSlot) => {
    if (acc.length === 0) return [activeSlot.date.format(dateFormat)];
    const newAccSlots = [...acc];
    if (!acc.includes(activeSlot.date.format(dateFormat))) {
      newAccSlots.push(activeSlot.date.format(dateFormat));
    }
    return newAccSlots;
  }, []);

  return activeSlotDates;
}

export function getWrittenDate(slot, index, slots) {
  const writtenDateFormat = 'D MMMM YYYY';
  let rdvDate = moment(slot.begin).format(writtenDateFormat);
  const previousRdvDate = index > 0 ? moment(slots[index - 1].begin).format(writtenDateFormat) : '';
  if (previousRdvDate === rdvDate) {
    rdvDate = index === (slots.length - 1) ? 'et' : ',';
  } else {
    rdvDate = `${moment(slot.begin).format('dddd')} ${rdvDate},`;
    rdvDate = rdvDate.substr(0, 1).toUpperCase() + rdvDate.substr(1);
  }
  const slotFormat = 'H[h]mm';
  const rdvBegin = slot.text ? slot.text.toLowerCase() : moment(slot.begin).format(slotFormat).replace(/00/g, '');
  const rdvEnd = slot.text ? undefined : moment(slot.end).format(slotFormat).replace(/00/g, '');

  return { rdvDate, rdvBegin, rdvEnd };
}
