import React from 'react';
import Layout from '../../layouts/index';
import Step1Container from './containers/Step1Container';
import MenuBar from '../../components/home/MenuBar';

const Step1Clothes = () => (
  <Layout slug="Step1">
    <MenuBar />
    <Step1Container />
  </Layout>
);
export default Step1Clothes;
