export default {
  defaultDeliveryFee: 0,
  defaultMinOrderAmount: 20,
  defaultMinTotalCouturier: 14,
  commissionRatio: 0.3,
  tvaRatio: 0.2,
  cancelFee: 7, // TTC for customer
  cancelFeeCommission: 0.833, // HT
};

export const fabrics = [
  { name: 'dentelle', slug: 'lace' },
  { name: 'cuir', slug: 'leather' },
  { name: 'soie/mousseline', slug: 'silk' },
  { name: 'tailleur', slug: 'suit' },
  { name: 'maille', slug: 'stitch' },
  { name: 'broderie', slug: 'embroidery' },
  { name: 'jeans', slug: 'jeans' },
  { name: 'rideaux', slug: 'curtain', cat: 'curtains' },
  { name: 'création d\'accessoires', slug: 'creation' },
  { name: 'lingerie', slug: 'lingerie' },
  { name: 'sport & bain', slug: 'bath' },
  { name: 'upcycling', slug: 'upcycling' },
  { name: 'masque', slug: 'mask' },
];

export const customerSources = [
  { slug: 'cancel', label: 'Annulation', hidden: true },
  { slug: 'blog', label: 'Blog / forum', specificationLabel: 'nom du blog ou du forum' },
  { slug: 'company', label: 'Entreprise', specificationLabel: 'nom de l‘entreprise' },
  { slug: 'journalist', label: 'Article de presse', specificationLabel: 'nom du journal ou magazine' },
  { slug: 'brand', label: 'Marque partenaire tilli', specificationLabel: 'nom de la marque' },
  {
    slug: 'searchEngine',
    label: 'Moteur de recherche',
    specificationLabel: 'recherche effectuée, par ex : service de retouche',
  },
  {
    slug: 'appStore',
    label: 'Recherche App Store / Play Store',
    specificationLabel: 'recherche effectuée, par ex : service de retouche',
  },
  { slug: 'newsletter', label: 'Newsletter', specificationLabel: '' },
  { slug: 'personalNetwork', label: 'Réseau personnel des fondateurs', specificationLabel: 'nom du fondateur' },
  {
    slug: 'socialNetwork',
    label: 'Réseaux sociaux',
    specificationLabel:
      'quel réseau social : facebook, instagram ? Quelle photo, article ou vidéo a retenu l‘attention ?',
  },
  { slug: 'theOZ', label: 'The Other store/ancien the oz', hidden: true },
  { slug: 'flyer', label: 'Flyer', specificationLabel: 'quel flyer ?' },
  { slug: 'mouthToWord', label: 'Bouche à oreille / parrainage', specificationLabel: 'par qui' },
  { slug: 'other', label: 'Autre / ne sait pas', specificationLabel: '' },
];

export const maduraShops = [
  'Paris 1',
  'BHV Marais',
  'Paris 6',
  'Paris 8',
  'Galeries Lafayette Haussmann',
  'Beaugrenelle',
  'C.C. Velizy 2',
  'So Ouest',
  'Domus',
  'Parly 2',
  'Marseille',
  'Aix-en-Provence',
  'Metz',
];
