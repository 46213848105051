import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels } from './styledComponents';

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 5px;
  height: ${props => props.height}px;
  margin-top: 1px;
  @media (max-width: ${mobileThresholdPixels}) {
    display: none;
  }
`;

const ProgressDot = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  height: 14px;
  min-height: 14px;
  width: 14px;
  border-radius: 50%;
  background: ${({ isActive }) => isActive ? colors.navy : colors.blueGrey};
  border: 1px solid ${colors.lightGrey};
  margin: 3px 0px;
`;

const Line = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${({ isActive }) => isActive ? colors.navy : colors.blueGrey};
`;

const ProgressBarVertical = ({ isActive, height, noLine }) => (
  <ProgressBarContainer height={height}>
    <ProgressDot isActive={isActive} />
    {noLine ? '' : <Line isActive={isActive} />}
  </ProgressBarContainer>
);

ProgressBarVertical.propTypes = {
  isActive: PropTypes.bool,
  height: PropTypes.number.isRequired,
  noLine: PropTypes.bool,
};

ProgressBarVertical.defaultProps = {
  isActive: false,
  noLine: false,
};

export default ProgressBarVertical;
