const tree = [
  {
    name: 'Pantalon',
    slug: 'pant',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'tooLargeLeg',
                label: 'Sur les jambes',
                id: '9.pant-cin-2',
              },
              {
                slug: 'tooLargeCrotch',
                label: 'À l’entrejambe',
                id: '9.pant-cin-4',
              },
              {
                slug: 'tooLargeBelt',
                label: 'À la ceinture',
                id: '9.pant-cin-1',
              },
              {
                slug: 'tooLargeLength',
                label: 'Sur la longueur',
                id: '9.pant-rac-1',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'tooSmallLeg',
                label: 'Sur les jambes',
                id: '9.pant-ela-2',
              },
              {
                slug: 'tooSmallCrotch',
                label: 'À l’entrejambe',
                id: '9.pant-ela-4',
              },
              {
                slug: 'tooSmallBelt',
                label: 'À la ceinture',
                id: '9.pant-ela-1',
              },
              {
                slug: 'tooSmallLength',
                label: 'Sur la longueur',
                id: '9.pant-ral-1',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'pocket',
                label: 'Une poche est trouée',
                id: '9.pant-div-8',
                multiple: true,
              },
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.pant-div-13',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.pant-div-16',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.pant-div-20',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                id: '9.pant-rac-1',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Manteau & veste',
    slug: 'mant',
    hasNoTypes: true,
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                id: '9.mant-rac-2',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                id: '9.mant-rac-1',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                id: '9.mant-cin-4',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                id: '9.mant-cin-2',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop larges',
                id: '9.mant-cin-1',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                id: '9.mant-ral-2',
              },
              {
                slug: 'shortChest',
                label: 'Buste trop étroit',
                id: '9.mant-ela-4',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                id: '9.mant-ral-1',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                id: '9.mant-ela-2',
              },
              {
                slug: 'shoulderTooTight',
                label: 'Épaules trop serrées',
                id: '9.mant-ela-1',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'entireLining',
                label: 'Sur toute la doublure',
                id: '9.mant-div-8',
              },
              {
                slug: 'liningPart',
                label: 'Sur une partie de la doublure',
                id: '9.mant-div-9',
              },
              {
                slug: 'pocket',
                label: 'Une poche est trouée',
                id: '9.mant-div-10',
                multiple: true,
              },
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.mant-div-15',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.mant-div-18',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.mant-div-22',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Il nécessite un renfort',
                id: '9.mant-div-27',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Robe',
    slug: 'robe',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'tooLarge',
                label: 'Elle est trop large',
                id: '9.robe-cin-1',
              },
              {
                slug: 'tooLong',
                label: 'Elle est trop longue',
                id: '9.robe-rac-1',
              },
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                id: '9.robe-rac-4',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                id: '9.robe-cin-3',
              },
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                id: '9.robe-rac-6',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop large',
                id: '9.robe-cin-2',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                id: '9.robe-cin-5',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'tooShort',
                label: 'Elle est trop courte',
                id: '9.robe-ral-1',
              },
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                id: '9.robe-ral-4',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                id: '9.robe-ela-3',
              },
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                id: '9.robe-ral-6',
              },
              {
                slug: 'smallShoulder',
                label: 'Épaules trop étroite',
                id: '9.robe-ela-2',
              },
              {
                slug: 'smallChest',
                label: 'Buste trop étroit',
                id: '9.robe-ela-5',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.robe-div-14',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.robe-div-17',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                id: '9.robe-div-21',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                id: '9.robe-rac-1',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Haut',
    slug: 'haut',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                id: '9.haut-rac-2',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                id: '9.haut-rac-1',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                id: '9.haut-cin-4',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                id: '9.haut-cin-2',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop larges',
                id: '9.haut-cin-1',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                id: '9.haut-ral-2',
              },
              {
                slug: 'shortChest',
                label: 'Buste trop étroit',
                id: '9.haut-ela-4',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                id: '9.haut-ral-1',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                id: '9.haut-ela-2',
              },
              {
                slug: 'shoulderTooTight',
                label: 'Épaules trop serrées',
                id: '9.haut-ela-1',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.haut-div-15',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.haut-div-18',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.haut-div-22',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Il nécessite un renfort',
                id: '9.haut-div-25',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Jupe',
    slug: 'jupe',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'tooLarge',
                label: 'Elle est trop large',
                id: '9.jupe-cin-1',
              },
              {
                slug: 'tooLong',
                label: 'Elle est trop longue',
                id: '9.jupe-rac-1',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'tooSmall',
                label: 'Elle est trop étroite',
                id: '9.jupe-ela-1',
              },
              {
                slug: 'tooShort',
                label: 'Elle est trop courte',
                id: '9.jupe-ral-1',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.jupe-div-13',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.jupe-div-16',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                id: '9.jupe-div-20',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Elle nécessite un renfort',
                id: '9.jupe-div-23',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                id: '9.jupe-rac-1',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Pull & gilet',
    slug: 'pull',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                id: '9.pull-rac-2',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                id: '9.pull-rac-1',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                id: '9.pull-cin-5',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                id: '9.pull-cin-3',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop larges',
                id: '9.pull-cin-2',
              },
            ],

          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                id: '9.pull-ral-2',
              },
              {
                slug: 'shortChest',
                label: 'Buste trop étroit',
                id: '9.pull-ela-5',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                id: '9.pull-ral-1',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                id: '9.pull-ela-3',
              },
              {
                slug: 'shoulderTooTight',
                label: 'Épaules trop serrées',
                id: '9.pull-ela-2',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.pull-div-14',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.pull-div-17',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.pull-div-21',
                multiple: true,
              },
              {
                slug: 'reinforcement',
                label: 'Il nécessite un renfort',
                id: '9.pull-div-26',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Lingerie',
    slug: 'ling',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                id: '9.ling-rac-1',
              },
              {
                slug: 'chest',
                label: 'Au niveau de de la poitrine',
                id: '9.ling-cin-1',
              },
              {
                slug: 'tooLarge',
                label: 'Au niveau de la taille',
                id: '9.ling-cin-3',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                id: '9.ling-rac-3',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                id: '9.ling-ral-1',
              },
              {
                slug: 'chest',
                label: 'Au niveau de la poitrine',
                id: '9.ling-ela-1',
              },
              {
                slug: 'tooSmall',
                label: 'Au niveau de la taille',
                id: '9.ling-ela-2',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                id: '9.ling-ral-3',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'damagedElastic',
                label: 'Changement de l’élastique',
                id: '9.ling-div-7',
                multiple: true,
              },
              {
                slug: 'damagedWhale',
                label: 'Changement des baleines',
                id: '9.ling-div-3',
                multiple: true,
              },
              {
                slug: 'damagedSling',
                label: 'Changement des bretelles',
                id: '9.ling-div-1',
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.ling-div-18',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.ling-div-22',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Combinaison',
    slug: 'comb',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                id: '9.comb-rac-1',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                id: '9.comb-cin-2',
              },
              {
                slug: 'largeChest',
                label: 'Buste / Taille trop large',
                id: '9.comb-cin-3',
              },
              {
                slug: 'largeCrotch',
                label: 'À l’entrejambe',
                id: '9.comb-cin-8',
              },
              {
                slug: 'largeLeg',
                label: 'Jambes trop larges',
                id: '9.comb-cin-6',
              },
              {
                slug: 'longLeg',
                label: 'Jambes trop longues',
                id: '9.comb-rac-3',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                id: '9.comb-ral-1',
              },
              {
                slug: 'tightArm',
                label: 'Manches trop serrées',
                id: '9.comb-ela-2',
              },
              {
                slug: 'tightChest',
                label: 'Buste / Taille trop serrée',
                id: '9.comb-ela-3',
              },
              {
                slug: 'smallCrotch',
                label: 'À l’entrejambe',
                id: '9.comb-ela-8',
              },
              {
                slug: 'tightLeg',
                label: 'Jambes trop serrées',
                id: '9.comb-ela-6',
              },
              {
                slug: 'shortLeg',
                label: 'Jambes trop courtes',
                id: '9.comb-ral-3',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.comb-div-16',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.comb-div-19',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                id: '9.comb-div-23',
                multiple: true,
              },
              {
                slug: 'pocket',
                label: 'Une poche est trouée',
                id: '9.comb-div-11',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                id: '9.comb-rac-3',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Maillot de bain',
    slug: 'mail',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Il est trop grand',
            question: 'À quel endroit est-il trop grand ?',
            locations: [
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                id: '9.ling-rac-1',
              },
              {
                slug: 'chest',
                label: 'Au niveau de de la poitrine',
                id: '9.ling-cin-1',
              },
              {
                slug: 'tooLarge',
                label: 'Au niveau de la taille',
                id: '9.ling-cin-3',
              },
              {
                slug: 'tooLong',
                label: 'Il est trop long',
                id: '9.ling-rac-3',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Il est trop petit',
            question: 'À quel endroit est-il trop petit ?',
            locations: [
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                id: '9.ling-ral-1',
              },
              {
                slug: 'chest',
                label: 'Au niveau de la poitrine',
                id: '9.ling-ela-1',
              },
              {
                slug: 'tooSmall',
                label: 'Au niveau de la taille',
                id: '9.ling-ela-2',
              },
              {
                slug: 'tooShort',
                label: 'Il est trop court',
                id: '9.ling-ral-3',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'damagedElastic',
                label: 'Changement de l’élastique',
                id: '9.ling-div-7',
                multiple: true,
              },
              {
                slug: 'damagedWhale',
                label: 'Changement des baleines',
                id: '9.ling-div-3',
                multiple: true,
              },
              {
                slug: 'damagedSling',
                label: 'Changement des bretelles',
                id: '9.ling-div-1',
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.ling-div-18',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.ling-div-22',
                multiple: true,
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Costume',
    slug: 'cost',
    pieces: {
      jacket: {
        name: 'Veste de costume',
        hasNoTypes: true,
      },
      pants: {
        name: 'Pantalon de costume',
      },
    },
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Robe de mariée',
    slug: 'mari',
    hasNoTypes: true,
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'tooLarge',
            label: 'Elle est trop grande',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'tooLarge',
                label: 'Elle est trop large',
                id: '9.mari-cin-1',
              },
              {
                slug: 'tooLong',
                label: 'Elle est trop longue',
                id: '9.mari-rac-1',
              },
              {
                slug: 'longArm',
                label: 'Manches trop longues',
                id: '9.mari-rac-4',
              },
              {
                slug: 'largeArm',
                label: 'Manches trop larges',
                id: '9.mari-cin-3',
              },
              {
                slug: 'tooLargeSling',
                label: 'Au niveau des bretelles',
                id: '9.mari-rac-6',
              },
              {
                slug: 'largeShoulder',
                label: 'Épaules trop large',
                id: '9.mari-cin-2',
              },
              {
                slug: 'largeChest',
                label: 'Buste trop large',
                id: '9.mari-cin-5',
              },
            ],
          },
          {
            slug: 'tooSmall',
            label: 'Elle est trop petite',
            question: 'À quel endroit est-elle trop petite ?',
            locations: [
              {
                slug: 'tooShort',
                label: 'Elle est trop courte',
                id: '9.mari-ral-1',
              },
              {
                slug: 'shortArm',
                label: 'Manches trop courtes',
                id: '9.mari-ral-4',
              },
              {
                slug: 'armTooTight',
                label: 'Manches trop serrées',
                id: '9.mari-ela-3',
              },
              {
                slug: 'tooSmallSling',
                label: 'Au niveau des bretelles',
                id: '9.mari-ral-6',
              },
              {
                slug: 'smallShoulder',
                label: 'Épaules trop étroite',
                id: '9.mari-ela-2',
              },
              {
                slug: 'smallChest',
                label: 'Buste trop étroit',
                id: '9.mari-ela-5',
              },
            ],
          },
          {
            slug: 'damaged',
            label: 'Elle est abîmée',
            question: 'À quel endroit est-elle abîmée ?',
            locations: [
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.mari-div-15',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.mari-div-18',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Elle est déchirée / trouée',
                id: '9.mari-div-22',
                multiple: true,
              },
            ],
          },
          {
            slug: 'hem',
            label: 'Faire un ourlet',
            question: 'À quel endroit est-elle trop grande ?',
            locations: [
              {
                slug: 'hem',
                label: 'Sur la longueur',
                id: '9.mari-rac-1',
              },
            ],
          },
          {
            slug: 'talkRDV',
            label: 'En parler en RDV',
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Accessoire',
    slug: 'acce',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
        problems: [
          {
            slug: 'damaged',
            label: 'Il est abîmé',
            question: 'À quel endroit est-il abîmé ?',
            locations: [
              {
                slug: 'damagedElastic',
                label: 'Il faut changer l’élastique',
                id: '9.acce-div-5',
                multiple: true,
              },
              {
                slug: 'brokenZip',
                label: 'Le zip est bloqué',
                id: '9.acce-div-16',
                multiple: true,
              },
              {
                slug: 'tornHoley',
                label: 'Il est déchiré / troué',
                id: '9.acce-div-20',
                multiple: true,
              },
              {
                slug: 'button',
                label: 'Il manque un bouton',
                id: '9.acce-div-13',
                multiple: true,
              },
            ],
          },
          {
            slug: 'adjustment_acce_multiple',
            label: 'Besoin d’ajustement',
            id: '9.acce-div-6',
            multiple: true,
          },
        ],
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Rideau',
    slug: 'ride',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Coussin',
    slug: 'cous',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
  {
    name: 'Linge de maison',
    slug: 'mais',
    typesOfWork: [
      {
        label: 'Retouche',
        subLabel: 'Ajuster la taille, réparer',
        slug: 'alteration',
      },
      {
        label: 'Broderie',
        subLabel: 'Décorer à l’aide d’un motif',
        slug: 'embroidery',
      },
      {
        label: 'Upcycling',
        subLabel: 'Transformer l’aspect initial',
        slug: 'transformation',
      },
      {
        label: 'Création d’accessoires',
        subLabel: 'Récupérer pour créer',
        slug: 'creation',
      },
    ],
  },
];


const treeInverse = {};

tree.forEach((cloth) => {
  if (!cloth.typesOfWork) return;
  cloth.typesOfWork.forEach((typeOfWork) => {
    if (!typeOfWork.problems) return;
    typeOfWork.problems.forEach((problem) => {
      if (!problem.locations) return;
      problem.locations.forEach((location) => {
        treeInverse[`${cloth.slug}_${location.slug}`] = location;
      });
    });
  });
});


const multipleQuestions = {
  pocket: 'Combien de poches sont trouées ?',
  button: 'Combien de boutons manquent-ils ?',
  brokenZip: 'Combien de zips sont bloqués ?',
  tornHoley: 'Combien y’a-t-il de déchirures / trous ?',
  reinforcement: 'Combien de renforts sont nécessaires ?',
  damagedElastic: 'Combien d’élastiques doivent-être changés ?',
  damagedWhale: 'Combien de baleines doivent être changées ?',
  adjustment_acce: 'Combien de parties nécessitent un ajustement ?',
};

export { treeInverse, multipleQuestions };
export default tree;
