import { getVariousPricingsCategory } from './commonPricings';
// *** Version: 9 ***

/* eslint max-len: 0, object-curly-newline: 0 */

const variousPricingsName = 'Éléments de customisation';

const rawUpcyclingPricing = [
  {
    name: 'Manteau & veste',
    slug: 'mant',
    types: undefined,
    categories: [
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { DEFAULT: { min: 50, max: 60 } }, id: '9.mant-upc-1' },
          { name: 'Création de manches', prices: { DEFAULT: { min: 100, max: 130 } }, liningPrices: { DEFAULT: 25 }, id: '9.mant-upc-2' },
          { name: 'Structuration des épaules', prices: { DEFAULT: { min: 85, max: 100 } }, liningPrices: { DEFAULT: 25 }, id: '9.mant-upc-3' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { DEFAULT: { min: 95, max: 110 } }, id: '9.mant-upc-4' },
          { name: 'Création de col', prices: { DEFAULT: { min: 100, max: 140 } }, id: '9.mant-upc-5' },
        ],
      },
      {
        name: 'Haut global',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { DEFAULT: { min: 90, max: 130 } }, liningPrices: { DEFAULT: 40 }, id: '9.mant-upc-6' },
          { name: 'Rigidification : donner de la tenue', prices: { DEFAULT: { min: 60, max: 80 } }, liningPrices: { DEFAULT: 15 }, id: '9.mant-upc-7' },
        ],
      },
      getVariousPricingsCategory({ slug: 'mant', name: variousPricingsName, categorySlug: 'upc', type: 'COMPLEX', minIndex: 7 }),
    ],
  },


  {
    name: 'Haut',
    slug: 'haut',
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Habillé' },
    ],
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '9.haut-upc-1' },
          { name: 'Décolleté', prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '9.haut-upc-2' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '9.haut-upc-3' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.haut-upc-4' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.haut-upc-5' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '9.haut-upc-6' },
          { name: 'Création de col', prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '9.haut-upc-7' },
        ],
      },
      {
        name: 'Haut global',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 90, max: 130 } }, liningPrices: { SIMPLE: 25, COMPLEX: 40 }, id: '9.haut-upc-8' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 40, max: 60 }, COMPLEX: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '9.haut-upc-9' },
        ],
      },
      getVariousPricingsCategory({ slug: 'haut', name: variousPricingsName, categorySlug: 'upc', minIndex: 9 }),
    ],
  },


  {
    name: 'Pantalon',
    slug: 'pant',
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Tailleur' },
    ],
    categories: [
      {
        name: 'Structure globale',
        items: [
          { name: 'Cintrer de plus d’une taille', prices: { SIMPLE: { min: 60, max: 80 }, COMPLEX: { min: 80, max: 110 } }, liningPrices: { SIMPLE: 20, COMPLEX: 30 }, id: '9.pant-upc-1' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 40, max: 60 }, COMPLEX: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '9.pant-upc-2' },
        ],
      },
      getVariousPricingsCategory({ slug: 'pant', name: variousPricingsName, categorySlug: 'upc', minIndex: 3 }),
    ],
  },


  {
    name: 'Jupe',
    slug: 'jupe',
    types: [
      { slug: 'SIMPLE', name: 'Simple' },
      { slug: 'COMPLEX', name: 'Habillée' },
    ],
    categories: [
      {
        name: 'Jupe globale',
        items: [
          { name: 'Transformation > Pantalon', prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 100, max: 150 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.jupe-upc-1' },
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 90, max: 130 } }, liningPrices: { SIMPLE: 25, COMPLEX: 40 }, id: '9.jupe-upc-2' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 40, max: 60 }, COMPLEX: { min: 60, max: 80 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '9.jupe-upc-3' },
        ],
      },
      getVariousPricingsCategory({ slug: 'jupe', name: variousPricingsName, categorySlug: 'upc', minIndex: 4 }),
    ],
  },


  {
    name: 'Robe',
    slug: 'robe',
    types: [
      { slug: 'SIMPLE', name: 'Petite robe classique' },
      { slug: 'COMPLEX', name: 'Robe de soirée' },
    ],
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '9.robe-upc-1' },
          { name: 'Décolleté', prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '9.robe-upc-2' },
          { name: 'Séparation haut / bas', prices: { SIMPLE: { min: 50, max: 60 }, COMPLEX: { min: 60, max: 70 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '9.robe-upc-3' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '9.robe-upc-4' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.robe-upc-5' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 25, COMPLEX: 25 }, id: '9.robe-upc-6' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '9.robe-upc-7' },
          { name: 'Création de col', prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '9.robe-upc-8' },
        ],
      },
      {
        name: 'Bas de robe / jupe',
        items: [
          { name: 'Transfo > Pantalon', prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 100, max: 150 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.robe-upc-9' },
          { ref: '9.robe-upc-3' },
        ],
      },
      {
        name: 'Robe globale',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 90, max: 120 }, COMPLEX: { min: 130, max: 180 } }, liningPrices: { SIMPLE: 30, COMPLEX: 50 }, id: '9.robe-upc-10' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 50, max: 70 }, COMPLEX: { min: 60, max: 70 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '9.robe-upc-11' },
          { ref: '9.robe-upc-3' },
        ],
      },
      getVariousPricingsCategory({ slug: 'robe', name: variousPricingsName, categorySlug: 'upc', minIndex: 11 }),
    ],
  },


  {
    name: 'Robe de mariée',
    slug: 'mari',
    types: undefined,
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { DEFAULT: { min: 50, max: 80 } }, liningPrices: { DEFAULT: 15 }, id: '9.mari-upc-1' },
          { name: 'Décolleté', prices: { DEFAULT: { min: 110, max: 140 } }, liningPrices: { DEFAULT: 20 }, id: '9.mari-upc-2' },
          { name: 'Séparation haut / bas', prices: { DEFAULT: { min: 100, max: 130 } }, liningPrices: { DEFAULT: 25 }, id: '9.mari-upc-3' },
          { name: 'Cintrer d’une taille ou plus', prices: { DEFAULT: { min: 65, max: 80 } }, liningPrices: { DEFAULT: 15 }, id: '9.mari-upc-4' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { DEFAULT: { min: 80, max: 100 } }, id: '9.mari-upc-5' },
          { name: 'Création de manches', prices: { DEFAULT: { min: 110, max: 140 } }, liningPrices: { DEFAULT: 30 }, id: '9.mari-upc-6' },
          { name: 'Structuration des épaules', prices: { DEFAULT: { min: 110, max: 150 } }, liningPrices: { DEFAULT: 25 }, id: '9.mari-upc-7' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { DEFAULT: { min: 110, max: 150 } }, id: '9.mari-upc-8' },
          { name: 'Création de col', prices: { DEFAULT: { min: 140, max: 170 } }, id: '9.mari-upc-9' },
        ],
      },
      {
        name: 'Bas de robe / jupe',
        items: [
          { name: 'Transfo > Pantalon', prices: { DEFAULT: { min: 170, max: 220 } }, liningPrices: { DEFAULT: 25 }, id: '9.mari-upc-10' },
          { ref: '9.mari-upc-3' },
        ],
      },
      {
        name: 'Robe globale',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { DEFAULT: { min: 200, max: 250 } }, liningPrices: { DEFAULT: 50 }, id: '9.mari-upc-11' },
          { name: 'Rigidification : donner de la tenue', prices: { DEFAULT: { min: 90, max: 120 } }, liningPrices: { DEFAULT: 25 }, id: '9.mari-upc-12' },
        ],
      },
      getVariousPricingsCategory({ slug: 'mari', name: variousPricingsName, categorySlug: 'upc', type: 'mari', minIndex: 12 }),
    ],
  },

  {
    name: 'Combinaison',
    slug: 'comb',
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Tailleur' },
    ],
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '9.comb-upc-1' },
          { name: 'Décolleté', prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '9.comb-upc-2' },
          { name: 'Séparation haut / bas', prices: { SIMPLE: { min: 50, max: 60 }, COMPLEX: { min: 60, max: 70 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '9.comb-upc-3' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '9.comb-upc-4' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.comb-upc-5' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.comb-upc-6' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '9.comb-upc-7' },
          { name: 'Création de col', prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '9.comb-upc-8' },
        ],
      },
      {
        name: 'Bas de combinaison (jupe ou pantalon)',
        items: [
          { name: 'Transfo Jupe > Pantalon', prices: { SIMPLE: { min: 60, max: 90 }, COMPLEX: { min: 100, max: 150 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '9.comb-upc-9' },
          { ref: '9.comb-upc-3' },
        ],
      },
      {
        name: 'Combinaison globale',
        items: [
          { name: 'Cintrer d’une taille intégralement ou plus', prices: { SIMPLE: { min: 90, max: 120 }, COMPLEX: { min: 130, max: 180 } }, liningPrices: { SIMPLE: 30, COMPLEX: 50 }, id: '9.comb-upc-10' },
          { name: 'Rigidification : donner de la tenue', prices: { SIMPLE: { min: 50, max: 70 }, COMPLEX: { min: 70, max: 90 } }, liningPrices: { SIMPLE: 10, COMPLEX: 15 }, id: '9.comb-upc-11' },
          { ref: '9.comb-upc-3' },
        ],
      },
      getVariousPricingsCategory({ slug: 'comb', name: variousPricingsName, categorySlug: 'upc', minIndex: 11 }),
    ],
  },

  {
    name: 'Pull & gilet',
    slug: 'pull',
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Laine & cachemire' },
    ],
    categories: [
      {
        name: 'Bustier / Taille',
        items: [
          { name: 'Ouverture dos', prices: { SIMPLE: { min: 15, max: 20 }, COMPLEX: { min: 20, max: 30 } }, liningPrices: { SIMPLE: 6, COMPLEX: 8 }, id: '10.pull-upc-1' },
          { name: 'Décolleté', prices: { SIMPLE: { min: 50, max: 65 }, COMPLEX: { min: 65, max: 80 } }, liningPrices: { SIMPLE: 8, COMPLEX: 10 }, id: '10.pull-upc-2' },
        ],
      },
      {
        name: 'Manches / Épaules',
        items: [
          { name: 'Ajout ornements manches', prices: { SIMPLE: { min: 40, max: 50 }, COMPLEX: { min: 50, max: 60 } }, id: '10.pull-upc-3' },
          { name: 'Création de manches', prices: { SIMPLE: { min: 80, max: 100 }, COMPLEX: { min: 100, max: 130 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '10.pull-upc-4' },
          { name: 'Structuration des épaules', prices: { SIMPLE: { min: 70, max: 85 }, COMPLEX: { min: 85, max: 100 } }, liningPrices: { SIMPLE: 15, COMPLEX: 25 }, id: '10.pull-upc-5' },
        ],
      },
      {
        name: 'Col',
        items: [
          { name: 'Revue du design de l’encolure', prices: { SIMPLE: { min: 80, max: 95 }, COMPLEX: { min: 95, max: 110 } }, id: '10.pull-upc-6' },
          { name: 'Création de col', prices: { SIMPLE: { min: 70, max: 100 }, COMPLEX: { min: 100, max: 140 } }, id: '10.pull-upc-7' },
        ],
      },
      getVariousPricingsCategory({ slug: 'pull', name: variousPricingsName, categorySlug: 'upc', minIndex: 9 }),
    ],
  },
];

const upcyclingPricing = rawUpcyclingPricing.map(cloth => ({
  ...cloth,
  categories: cloth.categories.map(category => ({
    ...category,
    items: category.items.map(item => ({
      ...item,
      hasPriceRange: !!item.prices?.SIMPLE?.min || !!item.prices?.COMPLEX?.min || !!item.prices?.DEFAULT?.min,
    })),
  })),
}));

const upcyclingPricingInverse = {};
upcyclingPricing.forEach(
  cloth => cloth.categories.forEach(
    category => category.items
      .filter(({ ref }) => !ref)
      .forEach(
        (item) => {
          if (upcyclingPricingInverse[item.id]) {
            throw new Error(`Inverse pricing, duplicate key : ${item.id}`);
          }
          upcyclingPricingInverse[item.id] = {
            ...item,
            cloth: cloth.name,
            category: category.name,
          };
        },
      ),
  ),
);
export { upcyclingPricingInverse };

export default upcyclingPricing;
