import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withAppContext from '../../withAppContext';
import {
  Row, Title, Content, CardsContainer, mobileThresholdPixels, fontSizes,
} from './styledComponents';
import Dots from '../home/v3/Dots';
import CardSelector from './CardSelector';
import fabrics from './fabrics';

const Text = styled.p`
  font-family: Roboto;
  line-height: 28px;
  font-size: ${fontSizes.m};
  text-align: center;
  margin: 40px 37px 0px 37px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px 13px 0px;
    line-height: 17px;
    font-size: 14px;
  }
`;

const Step1Fabrics = ({ context: { isMobile }, selectFabric, selectedFabric, selectedCloth }) => (
  <Row marginTop={83} marginTopMobile={50}>
    <Content>
      <Title>Une matière particulière ?</Title>
      <Dots justifyContent="center" noMargin />
      <Text>
        Certaines matières nécessitent un savoir-faire et des outils spécifiques.
        {isMobile ? ' ' : <br />}
        Si besoin, nous vous enverrons un spécialiste à votre client. Pas d’inquiétude, le prix reste le même.
      </Text>
      <Row>
        <CardsContainer>
          {fabrics.map((fabric) => {
            if (fabric.slug === 'leather' && selectedCloth === 'acce') return <div key={fabric.slug} />;
            return (
              <CardSelector
                key={fabric.slug}
                slug={fabric.slug}
                image={fabric.image}
                alt={`Tilli retouche vos vêtements en ${fabric.name}`}
                label={(isMobile && fabric.slug === 'other') ? 'Autre' : fabric.name}
                onClick={selectFabric}
                isActive={selectedFabric === fabric.slug}
              />
            );
          })}
        </CardsContainer>
      </Row>
    </Content>
  </Row>
);

Step1Fabrics.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  selectFabric: PropTypes.func.isRequired,
  selectedFabric: PropTypes.string.isRequired,
  selectedCloth: PropTypes.string.isRequired,
};

export default withAppContext(Step1Fabrics);
