export function getTreeProblems(tree, selectedCloth, selectedTypeOfWork = 'alteration') {
  if (!selectedCloth || !selectedTypeOfWork) return null;
  const clothTree = tree.find(cloth => cloth.slug === selectedCloth);
  const typesOfWorkTree = clothTree.typesOfWork.find(typeOfWork => typeOfWork.slug === selectedTypeOfWork);
  return typesOfWorkTree;
}

export function getTreeLocations(tree, selectedCloth, problemSlug, selectedTypeOfWork = 'alteration') {
  if (!selectedCloth || !selectedTypeOfWork || !problemSlug) return null;
  const clothTree = tree.find(cloth => cloth.slug === selectedCloth);
  const typesOfWorkTree = clothTree.typesOfWork.find(typeOfWork => typeOfWork.slug === selectedTypeOfWork);
  const selectedProblems = typesOfWorkTree.problems;
  const selectedProblem = selectedProblems.find(problem => problem.slug === problemSlug);
  return selectedProblem;
}
