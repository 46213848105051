// *** Version: 9 ***

/* eslint max-len: 0, object-curly-newline: 0 */
const version = 9;
export const piecesList = [
  'pant',
  'haut',
  'robe',
  'mari',
  'mant',
  'jupe',
  'pull',
  'comb',
  'ling',
  'acce',
  'deco',
  'crea_tote',
  'crea_sac',
  'crea_poch',
  'crea_hous',
];

export const piecesWithoutCreations = piecesList.filter(piece => !['crea_tote', 'crea_sac', 'crea_poch', 'crea_hous'].includes(piece));

const SMALL_ZIP = 'Zip < 25cm : ajout / remplacement / retrait';
const TEAR = 'Déchirure : réparation';
const SIMPLE_EXTRA_SEAM = 'Couture d’appoint / décousu - simple';
const PARTIAL_LINING = 'Doublure : changement partiel';
const BUTTON = 'Bouton (x1) : ajout / remplacement';
const HOLE = 'Trou : remaillage (< 10 trous)';
const ELBOW = 'Coudières (x2) : ajout / remplacement';
const BUTTONHOLE = 'Boutonnière (x1) : création';
const ELASTIC = 'Élastique : ajout / remplacement';

const mostCommonCategoryPositions = {
  mant: [
    { name: PARTIAL_LINING, position: 3 },
    { name: SMALL_ZIP, position: 4 },
    { name: TEAR, position: 5 },
  ],
  haut: [
    { name: BUTTON, position: 1 },
    { name: TEAR, position: 2 },
    { name: SIMPLE_EXTRA_SEAM, position: 3 },
  ],
  pant: [
    { name: SMALL_ZIP, position: 2 },
    { name: TEAR, position: 3 },
    { name: SIMPLE_EXTRA_SEAM, position: 4 },
  ],
  jupe: [
    { name: SMALL_ZIP, position: 2 },
    { name: TEAR, position: 3 },
    { name: SIMPLE_EXTRA_SEAM, position: 4 },
  ],
  robe: [
    { name: SMALL_ZIP, position: 2 },
    { name: TEAR, position: 3 },
    { name: SIMPLE_EXTRA_SEAM, position: 4 },
  ],
  pull: [
    { name: HOLE, position: 1 },
    { name: ELBOW, position: 2 },
    { name: BUTTONHOLE, position: 3 },
    { name: BUTTON, position: 4 },
  ],
  ling: [
    { name: TEAR, position: 1 },
    { name: SIMPLE_EXTRA_SEAM, position: 2 },
    { name: ELASTIC, position: 3 },
  ],
  comb: [
    { name: SMALL_ZIP, position: 2 },
    { name: TEAR, position: 3 },
    { name: SIMPLE_EXTRA_SEAM, position: 4 },
  ],
  mari: [
    { name: SMALL_ZIP, position: 2 },
    { name: TEAR, position: 3 },
    { name: SIMPLE_EXTRA_SEAM, position: 4 },
  ],
};

export const getPricingsItemsWithIds = (items, categorySlug, slug, type, minIndex = 0) => items
  .map((pricing, index) => {
    const newIndex = minIndex + index + 1;
    const { name, prices, liningPrices, multiple } = pricing;
    const updatedPrices = type ? { DEFAULT: prices[type] } : { ...prices };
    delete updatedPrices.mari;

    let updatedLiningPrices;
    if (type) {
      if (liningPrices?.[type]) updatedLiningPrices = liningPrices?.[type] ? { DEFAULT: liningPrices[type] } : undefined;
    } else {
      updatedLiningPrices = { ...liningPrices };
    }
    if (updatedLiningPrices) delete updatedLiningPrices.mari;
    if (updatedLiningPrices && Object.keys(updatedLiningPrices).length === 0) updatedLiningPrices = undefined;

    const item = {
      name,
      prices: updatedPrices,
      id: `${version}.${slug}-${categorySlug}-${newIndex}`,
    };
    if (updatedLiningPrices) item.liningPrices = updatedLiningPrices;
    if (multiple) item.multiple = multiple;
    if (mostCommonCategoryPositions[slug]?.find(mostCommon => mostCommon.name === name)) {
      item.mostCommonCategoryPosition = mostCommonCategoryPositions[slug].find(mostCommon => mostCommon.name === name).position;
    }
    return item;
  });

const embroideryPricingsItems = [
  { name: 'Initiales', prices: { SIMPLE: 10, COMPLEX: 10, mari: 20 }, multiple: true },
  { name: 'Un mot (10 lettres - petite police)', prices: { SIMPLE: 15, COMPLEX: 15, mari: 28 }, multiple: true },
  { name: 'Un mot (10 lettres - grosse police)', prices: { SIMPLE: 45, COMPLEX: 45, mari: 52 }, multiple: true },
  { name: 'Un mot (10 lettres - grosse police pleine)', prices: { SIMPLE: 80, COMPLEX: 80, mari: 110 }, multiple: true },

  { name: 'Dessin creux forme simple < 5cm', prices: { SIMPLE: 10, COMPLEX: 10, mari: 18 }, multiple: true },
  { name: 'Dessin creux forme simple < 12cm', prices: { SIMPLE: 17, COMPLEX: 17, mari: 25 }, multiple: true },
  { name: 'Dessin creux forme simple < 25cm', prices: { SIMPLE: 25, COMPLEX: 25, mari: 35 }, multiple: true },

  { name: 'Dessin plein forme simple < 5cm', prices: { SIMPLE: 35, COMPLEX: 35, mari: 48 }, multiple: true },
  { name: 'Dessin plein complexe < 5cm', prices: { SIMPLE: 65, COMPLEX: 65, mari: 80 }, multiple: true },
  { name: 'Dessin plein forme simple < 12cm', prices: { SIMPLE: 55, COMPLEX: 55, mari: 71 }, multiple: true },
  { name: 'Dessin plein complexe < 12cm', prices: { SIMPLE: 130, COMPLEX: 130, mari: 200 }, multiple: true },

  { name: 'Dessin creux complexe < 5cm', prices: { SIMPLE: 15, COMPLEX: 15, mari: 25 }, multiple: true },
  { name: 'Dessin creux complexe < 12cm', prices: { SIMPLE: 25, COMPLEX: 25, mari: 35 }, multiple: true },
  { name: 'Dessin creux complexe < 25cm', prices: { SIMPLE: 40, COMPLEX: 40, mari: 45 }, multiple: true },
];

export const getEmbroideryPricingsCategory = (slug, type = undefined) => ({
  name: 'Broderie main',
  items: getPricingsItemsWithIds(embroideryPricingsItems, 'bro', slug, type),
});

const variousPricingsItems = [
  { name: 'Bretelles (x2) : ajout / remplacement / retrait', prices: { SIMPLE: 12, COMPLEX: 18, mari: 45 }, pieces: ['haut', 'robe', 'jupe', 'mari', 'comb', 'ling'] },
  { name: 'Épaulettes (x2) : ajout / remplacement / retrait', prices: { SIMPLE: 18, COMPLEX: 21, mari: 35 }, liningPrices: { SIMPLE: 2, COMPLEX: 2, mari: 8 }, pieces: ['mant', 'haut', 'robe', 'pull', 'comb', 'mari'] },
  { name: ELBOW, prices: { SIMPLE: 17, COMPLEX: 20 }, pieces: ['haut', 'pull', 'comb', 'mant'] },
  { name: 'Attache dos : remplacement', prices: { SIMPLE: 10, COMPLEX: 15 }, multiple: true, pieces: ['ling'] },
  { name: 'Baleines (x2) : remplacement / réparation', prices: { SIMPLE: 12, COMPLEX: 17 }, liningPrices: { SIMPLE: 8, COMPLEX: 17 }, multiple: true, pieces: ['ling'] },
  { name: 'Ceinture : ajout / remplacement', prices: { mari: 42 }, multiple: true, pieces: ['mari'] },
  { name: 'Passant (x1) : ajout / remplacement', prices: { SIMPLE: 4, COMPLEX: 8, mari: 10 }, multiple: true, pieces: piecesList },
  { name: 'Genouillères (x2) : ajout / remplacement', prices: { SIMPLE: 17, COMPLEX: 20 }, pieces: ['pant', 'comb'] },
  { name: 'Pince (x1) : ajout', prices: { SIMPLE: 8, COMPLEX: 12, mari: 25 }, liningPrices: { SIMPLE: 1, COMPLEX: 1, mari: 5 }, multiple: true, pieces: piecesList },
  { name: 'Fente : ajout / retrait', prices: { SIMPLE: 12, COMPLEX: 18, mari: 29 }, liningPrices: { SIMPLE: 4, COMPLEX: 6, mari: 6 }, multiple: true, pieces: piecesList },
  { name: ELASTIC, prices: { SIMPLE: 7, COMPLEX: 17, mari: 22 }, liningPrices: { SIMPLE: 1, COMPLEX: 1, mari: 3 }, multiple: true, pieces: piecesList },
  { name: 'Perlage (5 à 10 perles)', prices: { SIMPLE: 15, COMPLEX: 17, mari: 35 }, multiple: true, pieces: piecesList },
  { name: 'Doublure : changement intégral', prices: { COMPLEX: 68 }, pieces: ['mant'] },
  { name: PARTIAL_LINING, prices: { COMPLEX: 45 }, pieces: ['mant'] },
  { name: 'Doublure : ajout / remplacement', prices: { SIMPLE: 35, COMPLEX: 40, mari: 69 }, pieces: piecesWithoutCreations.filter(piece => !['mant'].includes(piece)) },
  { name: 'Poche (x1) : changement de doublure', prices: { SIMPLE: 10, COMPLEX: 12, mari: 15 }, multiple: true, pieces: piecesWithoutCreations },
  { name: 'Poche plate simple (x1) : ajout', prices: { SIMPLE: 10, COMPLEX: 15, mari: 18 }, multiple: true, pieces: piecesList },
  { name: 'Poche intérieure classique (x1) : ajout', prices: { SIMPLE: 15, COMPLEX: 19, mari: 22 }, multiple: true, pieces: piecesWithoutCreations },
  { name: 'Poche complexe (x1) : ajout', prices: { SIMPLE: 20, COMPLEX: 22, mari: 27 }, multiple: true, pieces: piecesList },
  { name: BUTTONHOLE, prices: { SIMPLE: 3, COMPLEX: 4, mari: 10 }, liningPrices: { SIMPLE: 1, COMPLEX: 1, mari: 2 }, multiple: true, pieces: piecesList },
  { name: BUTTON, prices: { SIMPLE: 2, COMPLEX: 3, mari: 4 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: 'Bouton pression (x1) : ajout / remplacement', prices: { SIMPLE: 3, COMPLEX: 4, mari: 5 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: 'Bouton (x1) : pose simple', prices: { SIMPLE: 1, COMPLEX: 1, mari: 2 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: SMALL_ZIP, prices: { SIMPLE: 15, COMPLEX: 22, mari: 49 }, liningPrices: { SIMPLE: 3, COMPLEX: 3, mari: 13 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: 'Zip long : ajout / remplacement / retrait', prices: { SIMPLE: 24, COMPLEX: 29, mari: 56 }, liningPrices: { SIMPLE: 5, COMPLEX: 7, mari: 12 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: 'Zip invisible < 25cm : ajout / remplacement / retrait', prices: { SIMPLE: 17, COMPLEX: 23, mari: 56 }, liningPrices: { SIMPLE: 3, COMPLEX: 4, mari: 12 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: 'Zip invisible long : ajout / remplacement / retrait', prices: { SIMPLE: 20, COMPLEX: 26, mari: 63 }, liningPrices: { SIMPLE: 3, COMPLEX: 4, mari: 15 }, multiple: true, pieces: piecesList, isClosing: true },
  { name: TEAR, prices: { SIMPLE: 10, COMPLEX: 12, mari: 20 }, liningPrices: { mari: 8 }, multiple: true, pieces: piecesList },
  { name: HOLE, prices: { SIMPLE: 4, COMPLEX: 4 }, multiple: true, pieces: ['mant', 'pull'] },
  { name: 'Trou : remaillage (> 10 trous)', prices: { SIMPLE: 6, COMPLEX: 6 }, multiple: true, pieces: ['mant', 'pull'] },
  { name: SIMPLE_EXTRA_SEAM, prices: { SIMPLE: 4, COMPLEX: 6, mari: 11 }, multiple: true, pieces: piecesList },
  { name: 'Couture d’appoint / décousu - complexe', prices: { SIMPLE: 10, COMPLEX: 12, mari: 20 }, multiple: true, pieces: piecesList },
  { name: 'Couture complexe avec empiècement', prices: { SIMPLE: 15, COMPLEX: 17, mari: 35 }, multiple: true, pieces: piecesList },
];

export const getFilteredVariousPricingsItems = (slug, itemsToExclude = undefined) => variousPricingsItems.filter(({ name, pieces }) => pieces.includes(slug) && !itemsToExclude?.includes[name]);

export const getVariousPricingsCategory = ({
  slug, name = 'Divers', type = undefined, categorySlug = 'div', minIndex = 0, itemsToExclude = undefined,
}) => ({
  name,
  items: getPricingsItemsWithIds(getFilteredVariousPricingsItems(slug, itemsToExclude), categorySlug, slug, type, minIndex),
});

export const getVariousPricingsItemsForCreation = (slug, type, sizes, autoComplexFactor, closingsOnly = false) => {
  let filteredVariousPricingsItems = getFilteredVariousPricingsItems(slug);
  if (closingsOnly) filteredVariousPricingsItems = filteredVariousPricingsItems.filter(({ isClosing }) => isClosing);
  const pricingsItems = [];
  filteredVariousPricingsItems.forEach(({ name, prices, multiple }) => {
    const creationPrices = {};
    sizes.forEach((size) => {
      creationPrices[`${type}_${size}`] = prices[type];
    });
    const item = {
      name,
      autoComplexFactor,
      prices: creationPrices,
    };
    if (multiple) item.multiple = multiple;
    pricingsItems.push(item);
  });
  return pricingsItems;
};
