import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import withOrderContext from '../../withOrderContext';
import tree, { treeInverse } from './tree';
import { pricingInverse, metadata } from '../../services/pricing';
import { getTotalPaid, getTotalClothes, getMinOrderAmountFee, getClothTotal }
  from '../../core/services/orderPrice';
import { formatCloth } from '../../services/orderFormatting';
import routesMap from '../../Routes';

import OrderSummary from './OrderSummary';

class OrderSummaryContainer extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.setEditingClothIndex = this.setEditingClothIndex.bind(this);
    this.setPromoCodeInput = this.setPromoCodeInput.bind(this);
  }

  setPromoCodeInput(promoCodeInput) {
    this.setState({ promoCodeInput });
  }

  setEditingClothIndex(index) {
    const { saveCloth, orderContext: { setEditingClothIndex }, setEditingCloth } = this.props;
    saveCloth();
    setEditingClothIndex(index, setEditingCloth);
    navigate(routesMap.Step1.url);
  }

  render() {
    const {
      clothes, selectedDates, vertical,
      orderContext: {
        deliveryFee, customer = {}, deleteCloth, discounts,
      },
      onTotalPaid, hasZipcode, canEdit,
      showDiscountButtons, promoCode, checkPromoCode, removePromoCode, errorPromoCode,
    } = this.props;
    const { promoCodeInput } = this.state;

    const { discountVouchers } = customer;
    const { minOrderAmount, version: pricingVersion } = metadata;

    const order = {
      deliveryFee,
      minOrderAmount,
      pricingVersion,
      clothes: clothes
        .map(cloth => formatCloth(tree, treeInverse, pricingInverse, cloth, true)),
      discounts,
    };

    const formattedClothes = order.clothes.map((cloth, index) => ({
      index,
      name: cloth.name,
      items: cloth.items,
      typeOfWork: cloth.typeOfWork,
      total: getClothTotal(cloth),
      fabrics: [cloth.fabric],
    }));

    const totalPaid = getTotalPaid(order);
    const totalClothes = getTotalClothes(order);
    const minOrderAmountFee = getMinOrderAmountFee(order);
    const hasUndefinedPrice = formattedClothes.reduce((acc, cloth) => !acc && cloth.total === 0, false);

    onTotalPaid(totalPaid);

    return (
      <OrderSummary
        deliveryFee={deliveryFee}
        minOrderAmountFee={minOrderAmountFee}
        totalClothes={totalClothes}
        totalPaid={totalPaid}
        clothes={formattedClothes}
        selectedDates={selectedDates}
        vertical={vertical}
        hasUndefinedPrice={hasUndefinedPrice}
        discountVouchersValue={discountVouchers && discountVouchers.length > 0
          ? discountVouchers
            .filter(discountVoucher => discountVoucher.unit === 'EUROS')
            .filter(discountVoucher => !discountVoucher.usedByOrderId)
            .map(discountVoucher => discountVoucher.value)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
          : 0}
        hasZipcode={hasZipcode}
        canEdit={canEdit}
        setEditingClothIndex={this.setEditingClothIndex}
        deleteCloth={deleteCloth}
        showDiscountButtons={showDiscountButtons}
        promoCode={promoCode}
        checkPromoCode={checkPromoCode}
        removePromoCode={removePromoCode}
        errorPromoCode={errorPromoCode}
        setPromoCodeInput={this.setPromoCodeInput}
        promoCodeInput={promoCodeInput}
      />
    );
  }
}

OrderSummaryContainer.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({
    selectedCloth: PropTypes.string.isRequired,
    selectedLining: PropTypes.string.isRequired,
    selectedProblems: PropTypes.shape({}),
    selectedFabric: PropTypes.string.isRequired,
  })),
  selectedDates: PropTypes.shape({}),
  vertical: PropTypes.bool,
  saveCloth: PropTypes.func,
  setEditingCloth: PropTypes.func,
  onTotalPaid: PropTypes.func,
  hasZipcode: PropTypes.bool,
  canEdit: PropTypes.bool,
  showDiscountButtons: PropTypes.bool,
  promoCode: PropTypes.shape({}),
  checkPromoCode: PropTypes.func,
  removePromoCode: PropTypes.func,
  errorPromoCode: PropTypes.bool,
  orderContext: PropTypes.shape({
    deliveryFee: PropTypes.number.isRequired,
    customer: PropTypes.shape({
      discountVouchers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    setEditingClothIndex: PropTypes.func,
  }).isRequired,
};

OrderSummaryContainer.defaultProps = {
  clothes: [],
  selectedDates: null,
  vertical: false,
  saveCloth: () => {},
  setEditingCloth: () => {},
  onTotalPaid: () => {},
  hasZipcode: false,
  canEdit: false,
  showDiscountButtons: false,
  promoCode: null,
  removePromoCode: () => {},
  checkPromoCode: () => {},
  errorPromoCode: false,
};

export default withOrderContext(OrderSummaryContainer);
