import { getEmbroideryPricingsCategory, getPricingsItemsWithIds, getFilteredVariousPricingsItems, getVariousPricingsCategory, getVariousPricingsItemsForCreation } from './commonPricings';

// *** Version: 9 ***

/* eslint max-len: 0, object-curly-newline: 0 */
const rawPricing = [
  {
    name: 'Manteau & veste',
    slug: 'mant',
    types: undefined,
    hasMostCommonCategory: true,
    showInPlugin: true,
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', prices: { DEFAULT: 47 }, liningPrices: { DEFAULT: 15 }, showInPlugin: true, id: '9.mant-cin-1' },
          { name: 'Manches (x2)', prices: { DEFAULT: 59 }, showInPlugin: true, id: '9.mant-cin-2' },
          { name: 'Taille', prices: { DEFAULT: 37 }, liningPrices: { DEFAULT: 13 }, showInPlugin: true, id: '9.mant-cin-3' },
          { name: 'Dos', prices: { DEFAULT: 20 }, liningPrices: { DEFAULT: 7 }, showInPlugin: true, id: '9.mant-cin-4', mostCommonCategoryPosition: 2, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', prices: { DEFAULT: 52 }, liningPrices: { DEFAULT: 18 }, showInPlugin: true, id: '9.mant-ela-1' },
          { name: 'Manches (x2)', prices: { DEFAULT: 64 }, showInPlugin: true, id: '9.mant-ela-2' },
          { name: 'Taille', prices: { DEFAULT: 46 }, liningPrices: { DEFAULT: 15 }, showInPlugin: true, id: '9.mant-ela-3' },
          { name: 'Dos', prices: { DEFAULT: 25 }, liningPrices: { DEFAULT: 8 }, showInPlugin: true, id: '9.mant-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', prices: { DEFAULT: 29 }, liningPrices: { DEFAULT: 12 }, showInPlugin: true, id: '9.mant-rac-1', mostCommonCategoryPosition: 1, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manche (x2 - par le bas)', prices: { DEFAULT: 25 }, liningPrices: { DEFAULT: 5 }, id: '9.mant-rac-2' },
          { name: 'Manche (x2 - par l’épaule)', prices: { DEFAULT: 65 }, liningPrices: { DEFAULT: 15 }, showInPlugin: true, id: '9.mant-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', prices: { DEFAULT: 35 }, liningPrices: { DEFAULT: 12 }, showInPlugin: true, id: '9.mant-ral-1' },
          { name: 'Manche (x2 - par le bas)', prices: { DEFAULT: 27 }, liningPrices: { DEFAULT: 25 }, id: '9.mant-ral-2' },
          { name: 'Manche (x2 - par l’épaule)', prices: { DEFAULT: 85 }, liningPrices: { DEFAULT: 25 }, showInPlugin: true, id: '9.mant-ral-3' },
        ],
      },
      getEmbroideryPricingsCategory('mant', 'SIMPLE'),
      getVariousPricingsCategory({ slug: 'mant', type: 'COMPLEX' }),
    ],
  },


  {
    name: 'Haut',
    slug: 'haut',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Habillé' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', prices: { SIMPLE: 14, COMPLEX: 29 }, showInPlugin: true, id: '9.haut-cin-1' },
          { name: 'Manches (x2)', prices: { SIMPLE: 14, COMPLEX: 29 }, showInPlugin: true, id: '9.haut-cin-2' },
          { name: 'Taille', prices: { SIMPLE: 14, COMPLEX: 25 }, showInPlugin: true, id: '9.haut-cin-3' },
          { name: 'Dos', type: 'SIMPLE', prices: { SIMPLE: 9, COMPLEX: 18 }, showInPlugin: true, id: '9.haut-cin-4', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', prices: { SIMPLE: 15, COMPLEX: 28 }, showInPlugin: true, id: '9.haut-ela-1' },
          { name: 'Manches (x2)', prices: { SIMPLE: 15, COMPLEX: 32 }, showInPlugin: true, id: '9.haut-ela-2' },
          { name: 'Taille', prices: { SIMPLE: 15, COMPLEX: 32 }, showInPlugin: true, id: '9.haut-ela-3' },
          { name: 'Dos', prices: { SIMPLE: 10, COMPLEX: 20 }, showInPlugin: true, id: '9.haut-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', prices: { SIMPLE: 8, COMPLEX: 21 }, showInPlugin: true, id: '9.haut-rac-1', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manches (x2 - par le bas)', prices: { SIMPLE: 10, COMPLEX: 23 }, showInPlugin: true, id: '9.haut-rac-2' },
          { name: 'Manches (x2 - par l’épaule)', prices: { SIMPLE: 28, COMPLEX: 65 }, id: '9.haut-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', prices: { SIMPLE: 10, COMPLEX: 24 }, showInPlugin: true, id: '9.haut-ral-1' },
          { name: 'Manches (x2 - par le bas)', prices: { SIMPLE: 12, COMPLEX: 27 }, showInPlugin: true, id: '9.haut-ral-2' },
          { name: 'Manches (x2 - par l’épaule)', prices: { SIMPLE: 30, COMPLEX: 75 }, id: '9.haut-ral-3' },
        ],
      },
      getEmbroideryPricingsCategory('haut'),
      getVariousPricingsCategory({ slug: 'haut' }),
    ],
  },


  {
    name: 'Pantalon',
    slug: 'pant',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Tailleur' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', prices: { SIMPLE: 32, COMPLEX: 42 }, liningPrices: { COMPLEX: 11 }, showInPlugin: true, id: '9.pant-cin-1' },
          { name: 'Jambes (x2)', prices: { SIMPLE: 42, COMPLEX: 45 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.pant-cin-2' },
          { name: 'Bas de jambes (x2)', prices: { SIMPLE: 22, COMPLEX: 24 }, liningPrices: { COMPLEX: 8 }, showInPlugin: true, id: '9.pant-cin-3', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', prices: { SIMPLE: 15, COMPLEX: 22 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.pant-cin-4' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', prices: { SIMPLE: 38, COMPLEX: 47 }, liningPrices: { COMPLEX: 12 }, showInPlugin: true, id: '9.pant-ela-1' },
          { name: 'Jambes (x2)', prices: { SIMPLE: 47, COMPLEX: 50 }, liningPrices: { COMPLEX: 10 }, showInPlugin: true, id: '9.pant-ela-2' },
          { name: 'Bas de jambes (x2)', prices: { SIMPLE: 27, COMPLEX: 27 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.pant-ela-3' },
          { name: 'Entrejambe', prices: { SIMPLE: 20, COMPLEX: 27 }, liningPrices: { COMPLEX: 10 }, showInPlugin: true, id: '9.pant-ela-4' },
        ],
      },
      {
        name: 'Ourlets',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple (x2)', prices: { SIMPLE: 12, COMPLEX: 17 }, liningPrices: { COMPLEX: 7 }, showInPlugin: true, id: '9.pant-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', prices: { SIMPLE: 15, COMPLEX: 20 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.pant-rac-2' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Jambes (x2)', prices: { SIMPLE: 15, COMPLEX: 20 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.pant-ral-1' },
          { name: 'Ourlet (x2) : suppression des revers', prices: { COMPLEX: 17 }, liningPrices: { COMPLEX: 7 }, showInPlugin: true, id: '9.pant-ral-2' },
        ],
      },
      getEmbroideryPricingsCategory('pant'),
      getVariousPricingsCategory({ slug: 'pant' }),
    ],
  },


  {
    name: 'Jupe',
    slug: 'jupe',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Simple' },
      { slug: 'COMPLEX', name: 'Habillée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', prices: { SIMPLE: 20, COMPLEX: 23 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.jupe-cin-2' },
          { name: 'Taille / ceinture', prices: { SIMPLE: 27, COMPLEX: 33 }, liningPrices: { SIMPLE: 3, COMPLEX: 5 }, showInPlugin: true, id: '9.jupe-cin-1', mostCommonCategoryPosition: 5 },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', prices: { SIMPLE: 22, COMPLEX: 25 }, liningPrices: { SIMPLE: 5, COMPLEX: 6 }, showInPlugin: true, id: '9.jupe-ela-1' },
          { name: 'Taille / ceinture', prices: { SIMPLE: 29, COMPLEX: 35 }, liningPrices: { SIMPLE: 5, COMPLEX: 6 }, showInPlugin: true, id: '9.jupe-ela-2' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple', prices: { SIMPLE: 12, COMPLEX: 20 }, liningPrices: { SIMPLE: 4, COMPLEX: 4 }, showInPlugin: true, id: '9.jupe-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', prices: { SIMPLE: 18, COMPLEX: 23 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.jupe-rac-2' },
          { name: 'Ourlet à volants', prices: { SIMPLE: 22, COMPLEX: 28 }, liningPrices: { SIMPLE: 5, COMPLEX: 6 }, showInPlugin: true, id: '9.jupe-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur simple', prices: { SIMPLE: 15, COMPLEX: 20 }, liningPrices: { SIMPLE: 4, COMPLEX: 4 }, showInPlugin: true, id: '9.jupe-ral-1' },
          { name: 'Longueur ample', prices: { SIMPLE: 20, COMPLEX: 23 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.jupe-ral-2' },
          { name: 'Longueur à volants', prices: { SIMPLE: 22, COMPLEX: 30 }, liningPrices: { SIMPLE: 5, COMPLEX: 5 }, showInPlugin: true, id: '9.jupe-ral-3' },
        ],
      },
      getEmbroideryPricingsCategory('jupe'),
      getVariousPricingsCategory({ slug: 'jupe' }),
    ],
  },


  {
    name: 'Robe',
    slug: 'robe',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Petite robe classique' },
      { slug: 'COMPLEX', name: 'Robe de soirée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', prices: { SIMPLE: 20, COMPLEX: 23 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-cin-1' },
          { name: 'Épaules (x2)', prices: { SIMPLE: 22, COMPLEX: 24 }, liningPrices: { SIMPLE: 3, COMPLEX: 4 }, showInPlugin: true, id: '9.robe-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { SIMPLE: 20, COMPLEX: 27 }, showInPlugin: true, id: '9.robe-cin-3' },
          { name: 'Manches (x2 - boutonnière)', prices: { SIMPLE: 32, COMPLEX: 34 }, showInPlugin: true, id: '9.robe-cin-4' },
          { name: 'Bustier / taille', prices: { SIMPLE: 25, COMPLEX: 33 }, liningPrices: { SIMPLE: 3, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', prices: { SIMPLE: 21, COMPLEX: 24 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-cin-6' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', prices: { SIMPLE: 23, COMPLEX: 26 }, liningPrices: { SIMPLE: 6, COMPLEX: 6 }, showInPlugin: true, id: '9.robe-ela-1' },
          { name: 'Épaules (x2)', prices: { SIMPLE: 25, COMPLEX: 30 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { SIMPLE: 25, COMPLEX: 29 }, showInPlugin: true, id: '9.robe-ela-3' },
          { name: 'Manches (x2 - boutonnière)', prices: { SIMPLE: 35, COMPLEX: 38 }, showInPlugin: true, id: '9.robe-ela-4' },
          { name: 'Bustier / taille', prices: { SIMPLE: 27, COMPLEX: 35 }, liningPrices: { SIMPLE: 3, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-ela-5' },
          { name: 'Dos', prices: { SIMPLE: 26, COMPLEX: 27 }, liningPrices: { SIMPLE: 5, COMPLEX: 6 }, showInPlugin: true, id: '9.robe-ela-6' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple', prices: { SIMPLE: 12, COMPLEX: 20 }, liningPrices: { SIMPLE: 4, COMPLEX: 4 }, showInPlugin: true, id: '9.robe-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', prices: { SIMPLE: 18, COMPLEX: 23 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-rac-2' },
          { name: 'Ourlet à volants', prices: { SIMPLE: 22, COMPLEX: 28 }, liningPrices: { SIMPLE: 5, COMPLEX: 6 }, showInPlugin: true, id: '9.robe-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { SIMPLE: 12, COMPLEX: 16 }, showInPlugin: true, id: '9.robe-rac-4' },
          { name: 'Manches (x2 - boutonnière)', prices: { SIMPLE: 22, COMPLEX: 26 }, showInPlugin: true, id: '9.robe-rac-5' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 8, COMPLEX: 18 }, showInPlugin: true, id: '9.robe-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur simple', prices: { SIMPLE: 15, COMPLEX: 20 }, liningPrices: { SIMPLE: 4, COMPLEX: 4 }, showInPlugin: true, id: '9.robe-ral-1' },
          { name: 'Longueur ample', prices: { SIMPLE: 20, COMPLEX: 23 }, liningPrices: { SIMPLE: 4, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-ral-2' },
          { name: 'Longueur à volants', prices: { SIMPLE: 22, COMPLEX: 30 }, liningPrices: { SIMPLE: 5, COMPLEX: 5 }, showInPlugin: true, id: '9.robe-ral-3' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { SIMPLE: 14, COMPLEX: 17 }, showInPlugin: true, id: '9.robe-ral-4' },
          { name: 'Manches (x2 - boutonnière)', prices: { SIMPLE: 25, COMPLEX: 28 }, showInPlugin: true, id: '9.robe-ral-5' },
          { name: 'Bretelles (x2)', prices: { SIMPLE: 12, COMPLEX: 20 }, showInPlugin: true, id: '9.robe-ral-6' },
        ],
      },
      getEmbroideryPricingsCategory('robe'),
      getVariousPricingsCategory({ slug: 'robe' }),
    ],
  },


  {
    name: 'Pull & gilet',
    slug: 'pull',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Laine & cachemire' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', prices: { SIMPLE: 16, COMPLEX: 40 }, showInPlugin: true, id: '9.pull-cin-1' },
          { name: 'Épaules (x2)', prices: { SIMPLE: 18, COMPLEX: 45 }, showInPlugin: true, id: '9.pull-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { SIMPLE: 21, COMPLEX: 52 }, showInPlugin: true, id: '9.pull-cin-3' },
          { name: 'Taille', prices: { SIMPLE: 18, COMPLEX: 45 }, showInPlugin: true, id: '9.pull-cin-4' },
          { name: 'Dos', prices: { SIMPLE: 11, COMPLEX: 27 }, showInPlugin: true, id: '9.pull-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', prices: { SIMPLE: 17, COMPLEX: 42 }, showInPlugin: true, id: '9.pull-ela-1' },
          { name: 'Épaules (x2)', prices: { SIMPLE: 19, COMPLEX: 47 }, showInPlugin: true, id: '9.pull-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { SIMPLE: 24, COMPLEX: 60 }, showInPlugin: true, id: '9.pull-ela-3' },
          { name: 'Taille', prices: { SIMPLE: 20, COMPLEX: 50 }, showInPlugin: true, id: '9.pull-ela-4' },
          { name: 'Dos', prices: { SIMPLE: 13, COMPLEX: 32 }, showInPlugin: true, id: '9.pull-ela-5' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', prices: { SIMPLE: 14, COMPLEX: 35 }, showInPlugin: true, id: '9.pull-rac-1' },
          { name: 'Manches (x2 - par le bas)', prices: { SIMPLE: 12, COMPLEX: 30 }, showInPlugin: true, id: '9.pull-rac-2' },
          { name: 'Manches (x2 - par l’épaule)', prices: { SIMPLE: 37, COMPLEX: 92 }, showInPlugin: true, id: '9.pull-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', prices: { SIMPLE: 17, COMPLEX: 42 }, showInPlugin: true, id: '9.pull-ral-1' },
          { name: 'Manches (x2 - par le bas)', prices: { SIMPLE: 12, COMPLEX: 30 }, showInPlugin: true, id: '9.pull-ral-2' },
          { name: 'Manches (x2 - par l’épaule)', prices: { SIMPLE: 37, COMPLEX: 92 }, showInPlugin: true, id: '9.pull-ral-3' },
        ],
      },
      getEmbroideryPricingsCategory('pull'),
      getVariousPricingsCategory({ slug: 'pull' }),
    ],
  },


  {
    name: 'Lingerie & maillot',
    slug: 'ling',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Lingerie fine' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Tour de poitrine', prices: { SIMPLE: 9, COMPLEX: 22 }, showInPlugin: true, id: '9.ling-cin-1' },
          { name: 'Bonnets (x2)', prices: { SIMPLE: 12, COMPLEX: 30 }, liningPrices: { SIMPLE: 3, COMPLEX: 3 }, showInPlugin: true, id: '9.ling-cin-2' },
          { name: 'Tour de taille', prices: { SIMPLE: 20, COMPLEX: 30 }, showInPlugin: true, id: '9.ling-cin-3' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Tour de poitrine', prices: { SIMPLE: 10, COMPLEX: 25 }, showInPlugin: true, id: '9.ling-ela-1' },
          { name: 'Tour de taille', prices: { SIMPLE: 20, COMPLEX: 40 }, showInPlugin: true, id: '9.ling-ela-2' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Bretelles (x2)', prices: { SIMPLE: 8, COMPLEX: 20 }, showInPlugin: true, id: '9.ling-rac-1' },
          { name: 'Forme culotte / caleçon', prices: { SIMPLE: 20, COMPLEX: 30 }, liningPrices: { SIMPLE: 2, COMPLEX: 2 }, showInPlugin: true, id: '9.ling-rac-2' },
          { name: 'Ourlet', prices: { SIMPLE: 12 }, liningPrices: { SIMPLE: 2 }, showInPlugin: true, id: '9.ling-rac-3', mostCommonCategoryPosition: 5 },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Bretelles (x2)', prices: { SIMPLE: 9, COMPLEX: 22 }, showInPlugin: true, id: '9.ling-ral-1' },
          { name: 'Forme culotte / caleçon', prices: { SIMPLE: 22, COMPLEX: 35 }, liningPrices: { SIMPLE: 2, COMPLEX: 2 }, showInPlugin: true, id: '9.ling-ral-2' },
          { name: 'Longueur', prices: { SIMPLE: 14 }, liningPrices: { SIMPLE: 3 }, showInPlugin: true, id: '9.ling-ral-3' },
        ],
      },
      getEmbroideryPricingsCategory('ling'),
      getVariousPricingsCategory({ slug: 'ling' }),
    ],
  },

  {
    name: 'Combinaison',
    slug: 'comb',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Tailleur' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', prices: { SIMPLE: 14.5, COMPLEX: 29 }, showInPlugin: true, id: '9.comb-cin-1' },
          { name: 'Manches (x2)', prices: { SIMPLE: 14.5, COMPLEX: 29 }, showInPlugin: true, id: '9.comb-cin-2' },
          { name: 'Taille', prices: { SIMPLE: 14.5, COMPLEX: 25 }, showInPlugin: true, id: '9.comb-cin-3' },
          { name: 'Dos', prices: { SIMPLE: 9, COMPLEX: 18 }, showInPlugin: true, id: '9.comb-cin-4' },
          { name: 'Ceinture', prices: { SIMPLE: 32, COMPLEX: 42 }, liningPrices: { COMPLEX: 11 }, showInPlugin: true, id: '9.comb-cin-5' },
          { name: 'Jambes (x2)', prices: { SIMPLE: 42, COMPLEX: 45 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.comb-cin-6' },
          { name: 'Bas de jambes (x2)', prices: { SIMPLE: 22, COMPLEX: 24 }, liningPrices: { COMPLEX: 8 }, showInPlugin: true, id: '9.comb-cin-7', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', prices: { SIMPLE: 15, COMPLEX: 22 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.comb-cin-8' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', prices: { SIMPLE: 15, COMPLEX: 32 }, showInPlugin: true, id: '9.comb-ela-1' },
          { name: 'Manches (x2)', prices: { SIMPLE: 15, COMPLEX: 32 }, showInPlugin: true, id: '9.comb-ela-2' },
          { name: 'Taille', prices: { SIMPLE: 15, COMPLEX: 28 }, showInPlugin: true, id: '9.comb-ela-3' },
          { name: 'Dos', prices: { SIMPLE: 10, COMPLEX: 20 }, showInPlugin: true, id: '9.comb-ela-4' },
          { name: 'Ceinture', prices: { SIMPLE: 38, COMPLEX: 47 }, liningPrices: { COMPLEX: 12 }, showInPlugin: true, id: '9.comb-ela-5' },
          { name: 'Jambes (x2)', prices: { SIMPLE: 47, COMPLEX: 50 }, liningPrices: { COMPLEX: 10 }, showInPlugin: true, id: '9.comb-ela-6' },
          { name: 'Bas de jambes (x2)', prices: { SIMPLE: 27, COMPLEX: 27 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.comb-ela-7' },
          { name: 'Entrejambe', prices: { SIMPLE: 20, COMPLEX: 27 }, liningPrices: { COMPLEX: 10 }, showInPlugin: true, id: '9.comb-ela-8' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Manches (x2 - par le bas)', prices: { SIMPLE: 10, COMPLEX: 23 }, showInPlugin: true, id: '9.comb-rac-1' },
          { name: 'Manches (x2 - par l’épaule)', prices: { SIMPLE: 28, COMPLEX: 65 }, showInPlugin: true, id: '9.comb-rac-2' },
          { name: 'Ourlet simple (x2)', prices: { SIMPLE: 12, COMPLEX: 17 }, liningPrices: { COMPLEX: 7 }, showInPlugin: true, id: '9.comb-rac-3', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', prices: { SIMPLE: 15, COMPLEX: 20 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.comb-rac-4' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Manches (x2 - par le bas)', prices: { SIMPLE: 12, COMPLEX: 27 }, showInPlugin: true, id: '9.comb-ral-1' },
          { name: 'Manches (x2 - par l’épaule)', prices: { SIMPLE: 30, COMPLEX: 75 }, showInPlugin: true, id: '9.comb-ral-2' },
          { name: 'Jambes (x2)', prices: { SIMPLE: 15, COMPLEX: 20 }, liningPrices: { COMPLEX: 9 }, showInPlugin: true, id: '9.comb-ral-3' },
        ],
      },
      getEmbroideryPricingsCategory('comb'),
      getVariousPricingsCategory({ slug: 'comb' }),
    ],
  },


  {
    name: 'Décoration',
    slug: 'deco',
    hasMostCommonCategory: false,
    types: undefined,
    categories: [
      {
        name: 'Rideaux',
        items: [
          { name: 'Ourlet - largeur <199cm', prices: { DEFAULT: 20 }, liningPrices: { DEFAULT: 10 }, id: '9.deco-rid-1', multiple: true },
          { name: 'Ourlet - largeur >200cm', prices: { DEFAULT: 30 }, liningPrices: { DEFAULT: 10 }, id: '9.deco-rid-2', multiple: true },
          { name: 'Supplément fil de plomb', prices: { DEFAULT: 10 }, id: '9.deco-rid-3', multiple: true },
          { name: 'Assemblage côté - hauteur <199cm', prices: { DEFAULT: 30 }, liningPrices: { DEFAULT: 10 }, id: '9.deco-rid-4', multiple: true },
          { name: 'Assemblage côté - hauteur >200cm', prices: { DEFAULT: 40 }, liningPrices: { DEFAULT: 10 }, id: '9.deco-rid-5', multiple: true },
          { name: 'Ajout de fronces - largeur <199cm', prices: { DEFAULT: 30 }, id: '9.deco-rid-6', multiple: true },
          { name: 'Ajout de fronces - largeur >200cm', prices: { DEFAULT: 37 }, id: '9.deco-rid-7', multiple: true },
          { name: 'Dépose/pose d’un pan de rideau', prices: { DEFAULT: 7 }, id: '9.deco-rid-8', multiple: true },
          ...getPricingsItemsWithIds(getFilteredVariousPricingsItems('deco'), 'rid', 'deco', 'SIMPLE', 8),
        ],
      },
      {
        name: 'Coussins',
        items: [
          { name: 'Coussin (moins de 45 cm)', prices: { DEFAULT: 14 }, id: '9.deco-cou-1', multiple: true },
          { name: 'Coussin (plus de 45 cm)', prices: { DEFAULT: 18 }, id: '9.deco-cou-2', multiple: true },
          { name: 'Doublure : ajout / remplacement', prices: { DEFAULT: 9 }, id: '9.deco-cou-3' },
          ...getPricingsItemsWithIds(getFilteredVariousPricingsItems('deco', ['Doublure : ajout / remplacement']), 'cou', 'deco', 'SIMPLE', 3),
        ],
      },
      {
        name: 'Linge de maison',
        items: [
          { name: 'Plus d’1m60 - longueur', prices: { DEFAULT: 20 }, id: '9.deco-mai-1', multiple: true },
          { name: 'Plus d’1m60 - largeur', prices: { DEFAULT: 20 }, id: '9.deco-mai-2', multiple: true },
          { name: 'Plus d’1m60 - les deux', prices: { DEFAULT: 40 }, id: '9.deco-mai-3', multiple: true },
          { name: 'Moins d’1m60 - longueur', prices: { DEFAULT: 15 }, id: '9.deco-mai-4', multiple: true },
          { name: 'Moins d’1m60 - largeur', prices: { DEFAULT: 15 }, id: '9.deco-mai-5', multiple: true },
          { name: 'Moins d’1m60 - les deux', prices: { DEFAULT: 30 }, id: '9.deco-mai-6', multiple: true },
          { name: 'Moins d’1m - longueur', prices: { DEFAULT: 10 }, id: '9.deco-mai-7', multiple: true },
          { name: 'Moins d’1m - largeur', prices: { DEFAULT: 10 }, id: '9.deco-mai-8', multiple: true },
          { name: 'Moins d’1m - les deux', prices: { DEFAULT: 20 }, id: '9.deco-mai-9', multiple: true },
          ...getPricingsItemsWithIds(getFilteredVariousPricingsItems('deco'), 'mai', 'deco', 'SIMPLE', 9),
        ],
      },
      getEmbroideryPricingsCategory('deco', 'SIMPLE'),
    ],
  },

  {
    name: 'Robe de mariée',
    slug: 'mari',
    hasMostCommonCategory: true,
    types: undefined,
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Côtés (x2)', prices: { DEFAULT: 63 }, liningPrices: { DEFAULT: 28 }, id: '9.mari-cin-1' },
          { name: 'Épaules (x2)', prices: { DEFAULT: 42 }, liningPrices: { DEFAULT: 8 }, id: '9.mari-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { DEFAULT: 49 }, id: '9.mari-cin-3' },
          { name: 'Manches (x2 - boutonnière)', prices: { DEFAULT: 63 }, id: '9.mari-cin-4' },
          { name: 'Bustier / taille', prices: { DEFAULT: 70 }, liningPrices: { DEFAULT: 8 }, id: '9.mari-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', prices: { DEFAULT: 63 }, liningPrices: { DEFAULT: 8 }, id: '9.mari-cin-6' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Côtés (x2)', prices: { DEFAULT: 35 }, liningPrices: { DEFAULT: 12 }, id: '9.mari-ela-1' },
          { name: 'Épaules (x2)', prices: { DEFAULT: 42 }, liningPrices: { DEFAULT: 8 }, id: '9.mari-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { DEFAULT: 49 }, id: '9.mari-ela-3' },
          { name: 'Manches (x2 - boutonnière)', prices: { DEFAULT: 63 }, id: '9.mari-ela-4' },
          { name: 'Bustier / taille', prices: { DEFAULT: 70 }, liningPrices: { DEFAULT: 8 }, id: '9.mari-ela-5' },
          { name: 'Dos', prices: { DEFAULT: 63 }, liningPrices: { DEFAULT: 8 }, id: '9.mari-ela-6' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple', prices: { DEFAULT: 63 }, liningPrices: { DEFAULT: 13 }, id: '9.mari-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet à volants', prices: { DEFAULT: 84 }, liningPrices: { DEFAULT: 15 }, id: '9.mari-rac-2' },
          { name: 'Ourlet revers', prices: { DEFAULT: 65 }, liningPrices: { DEFAULT: 13 }, id: '9.mari-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { DEFAULT: 23 }, id: '9.mari-rac-4' },
          { name: 'Manches (x2 - boutonnière)', prices: { DEFAULT: 32 }, id: '9.mari-rac-5' },
          { name: 'Bretelles (x2)', prices: { DEFAULT: 28 }, id: '9.mari-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur simple', prices: { DEFAULT: 63 }, liningPrices: { DEFAULT: 13 }, id: '9.mari-ral-1' },
          { name: 'Longueur à volants', prices: { DEFAULT: 84 }, liningPrices: { DEFAULT: 15 }, id: '9.mari-ral-2' },
          { name: 'Ourlets (x2) : suppression des revers', prices: { DEFAULT: 59 }, liningPrices: { DEFAULT: 11 }, id: '9.mari-ral-3' },
          { name: 'Manches (x2 - sans boutonnière)', prices: { DEFAULT: 23 }, id: '9.mari-ral-4' },
          { name: 'Manches (x2 - boutonnière)', prices: { DEFAULT: 32 }, id: '9.mari-ral-5' },
          { name: 'Bretelles (x2)', prices: { DEFAULT: 28 }, id: '9.mari-ral-6' },
        ],
      },
      getEmbroideryPricingsCategory('mari', 'mari'),
      getVariousPricingsCategory({ slug: 'mari', type: 'mari' }),
    ],
  },


  {
    name: 'Accessoires',
    slug: 'acce',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE', name: 'Basique' },
      { slug: 'COMPLEX', name: 'Habillé' },
    ],
    categories: [
      getEmbroideryPricingsCategory('acce'),
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', prices: { SIMPLE: 9, COMPLEX: 12 }, id: '9.acce-div-1' },
          ...getPricingsItemsWithIds(getFilteredVariousPricingsItems('acce', ['Doublure : ajout / remplacement']), 'div', 'acce', undefined, 1),
        ],
      },
    ],
  },

  {
    name: 'Création de totebag',
    slug: 'crea_tote',
    hasMostCommonCategory: false,
    showInPlugin: false,
    autoId: true,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - classique : côtés < 50 cm' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - classique : côtés < 50 cm' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple - grand : côtés > 51 cm' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale - grand : côtés > 51 cm' },
    ],
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Classique : côtés < 50 cm', prices: { SIMPLE_SMALL: 10 } },
          { name: 'Grand : côtés > 51 cm', prices: { SIMPLE_BIG: 15 } },
        ],
      },
      {
        name: 'Anses',
        autoIdPrefix: 'anse',
        items: [
          { name: 'Chaîne + oeillets', prices: { SIMPLE_SMALL: 15, SIMPLE_BIG: 15 } },
          { name: 'Tissu', prices: { SIMPLE_SMALL: 2, SIMPLE_BIG: 3 } },
          { name: 'Autre / fourni par le client', prices: { SIMPLE_SMALL: 2, SIMPLE_BIG: 2 } },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: getVariousPricingsItemsForCreation('crea_tote', 'SIMPLE', ['SMALL', 'BIG'], 1, true),
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', prices: { SIMPLE_SMALL: 9, SIMPLE_BIG: 9 } },
          { name: 'Molleton', prices: { SIMPLE_SMALL: 5, SIMPLE_BIG: 7 } },
          { name: 'Thermocollant', prices: { SIMPLE_SMALL: 5, SIMPLE_BIG: 7 } },
          ...getVariousPricingsItemsForCreation('crea_tote', 'SIMPLE', ['SMALL', 'BIG'], 1),
        ],
      },
    ],
  },

  {
    name: 'Création de sac',
    slug: 'crea_sac',
    hasMostCommonCategory: false,
    autoId: true,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - petit : côtés < 35 cm' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - petit : côtés < 35 cm' },
      { slug: 'SIMPLE_MEDIUM', name: 'Forme simple - moyen : 36 cm < côtés < 50 cm' },
      { slug: 'COMPLEX_MEDIUM', name: 'Forme originale - moyen : 36 cm < côtés < 50 cm' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple : côtés > 51 cm' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale : côtés > 51 cm' },
    ],
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Petit : côtés < 35 cm', prices: { SIMPLE_SMALL: 10 } },
          { name: 'Moyen : 36 cm < côtés < 50 cm', prices: { SIMPLE_MEDIUM: 15 } },
          { name: 'Grand : côtés > 51 cm', prices: { SIMPLE_BIG: 20 } },
        ],
      },
      {
        name: 'Anses',
        autoIdPrefix: 'anse',
        items: [
          { name: 'Chaîne + oeillets', prices: { SIMPLE_SMALL: 15, SIMPLE_MEDIUM: 15, SIMPLE_BIG: 15 } },
          { name: 'Tissu', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 5, SIMPLE_BIG: 5 } },
          { name: 'Autre / fourni par le client', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 5, SIMPLE_BIG: 5 } },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: getVariousPricingsItemsForCreation('crea_sac', 'SIMPLE', ['SMALL', 'MEDIUM', 'BIG'], 1, true),
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', prices: { SIMPLE_SMALL: 9, SIMPLE_MEDIUM: 9, SIMPLE_BIG: 9 } },
          { name: 'Molleton', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 6, SIMPLE_BIG: 8 } },
          { name: 'Thermocollant', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 6, SIMPLE_BIG: 8 } },
          { name: 'Clous de protection', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 7, SIMPLE_BIG: 10 } },
          ...getVariousPricingsItemsForCreation('crea_sac', 'SIMPLE', ['SMALL', 'MEDIUM', 'BIG'], 1),
        ],
      },
    ],
  },

  {
    name: 'Création de pochette',
    slug: 'crea_poch',
    hasMostCommonCategory: false,
    autoId: true,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - petit (15x10cm)' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - petit (15x10cm)' },
      { slug: 'SIMPLE_MEDIUM', name: 'Forme simple - moyen (20x20cm)' },
      { slug: 'COMPLEX_MEDIUM', name: 'Forme originale - moyen (20x20cm)' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple - grand (>25cm)' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale - grand (>25cm)' },
    ],
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Petit 15x10cm', prices: { SIMPLE_SMALL: 7 } },
          { name: 'Moyen 20x20cm', prices: { SIMPLE_MEDIUM: 12 } },
          { name: 'Grand > 25cm', prices: { SIMPLE_BIG: 18 } },
        ],
      },
      {
        name: 'Anses',
        autoIdPrefix: 'anse',
        items: [
          { name: 'Chaîne + oeillets', prices: { SIMPLE_SMALL: 15, SIMPLE_MEDIUM: 15, SIMPLE_BIG: 15 } },
          { name: 'Tissu', prices: { SIMPLE_SMALL: 2, SIMPLE_MEDIUM: 2, SIMPLE_BIG: 2 } },
          { name: 'Autre / fourni par le client', prices: { SIMPLE_SMALL: 2, SIMPLE_MEDIUM: 2, SIMPLE_BIG: 2 } },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: getVariousPricingsItemsForCreation('crea_poch', 'SIMPLE', ['SMALL', 'MEDIUM', 'BIG'], 1, true),
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', prices: { SIMPLE_SMALL: 9, SIMPLE_MEDIUM: 9, SIMPLE_BIG: 9 } },
          { name: 'Molleton', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 7, SIMPLE_BIG: 8 } },
          { name: 'Thermocollant', prices: { SIMPLE_SMALL: 5, SIMPLE_MEDIUM: 7, SIMPLE_BIG: 8 } },
          ...getVariousPricingsItemsForCreation('crea_poch', 'SIMPLE', ['SMALL', 'MEDIUM', 'BIG'], 1),
        ],
      },
    ],
  },

  {
    name: 'Création de housse de coussin',
    slug: 'crea_hous',
    autoId: true,
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE_SMALL', name: 'Forme simple - petit (<45cm)' },
      { slug: 'COMPLEX_SMALL', name: 'Forme originale - petit (<45cm)' },
      { slug: 'SIMPLE_BIG', name: 'Forme simple - grand (>45cm)' },
      { slug: 'COMPLEX_BIG', name: 'Forme originale - grand (>45cm)' },
    ],
    autoComplexFactor: 2,
    categories: [
      {
        name: 'Dimensions',
        autoIdPrefix: 'dime',
        items: [
          { name: 'Petit < 45cm', prices: { SIMPLE_SMALL: 14 } },
          { name: 'Grand > 45cm', prices: { SIMPLE_BIG: 18 } },
        ],
      },
      {
        name: 'Fermetures',
        autoIdPrefix: 'ferm',
        autoMultiple: true,
        items: getVariousPricingsItemsForCreation('crea_hous', 'SIMPLE', ['SMALL', 'BIG'], 1, true),
      },
      {
        name: 'Suppléments / toppings',
        autoIdPrefix: 'supp',
        autoMultiple: true,
        items: [
          { name: 'Doublure', prices: { SIMPLE_SMALL: 9, SIMPLE_BIG: 9 } },
          { name: 'Molleton', prices: { SIMPLE_SMALL: 5, SIMPLE_BIG: 8 } },
          { name: 'Thermocollant', prices: { SIMPLE_SMALL: 5, SIMPLE_BIG: 8 } },
          ...getVariousPricingsItemsForCreation('crea_hous', 'SIMPLE', ['SMALL', 'BIG'], 1),
        ],
      },
    ],
  },

  {
    name: 'Masque',
    slug: 'mask',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: undefined,
    categories: [
      {
        name: 'Fabrication',
        items: [
          { name: 'Masque en tissu', prices: { DEFAULT: 10 }, id: '9.mask-fab-1' },
          { name: 'Masque en tissu adulte bleu avec lanières', prices: { DEFAULT: 10 }, id: '9.mask-fab-2' },
          { name: 'Masque en tissu adulte beige avec lanières', prices: { DEFAULT: 10 }, id: '9.mask-fab-3' },
          { name: 'Masque en tissu enfant bleu avec lanières', prices: { DEFAULT: 10 }, id: '9.mask-fab-4' },
          { name: 'Masque en tissu enfant beige avec lanières', prices: { DEFAULT: 10 }, id: '9.mask-fab-5' },
          { name: 'Masque en tissu adulte bleu avec élastiques', prices: { DEFAULT: 10 }, id: '9.mask-fab-6' },
          { name: 'Masque en tissu adulte beige avec élastiques', prices: { DEFAULT: 10 }, id: '9.mask-fab-7' },
          { name: 'Masque en tissu enfant bleu avec élastiques', prices: { DEFAULT: 10 }, id: '9.mask-fab-8' },
          { name: 'Masque en tissu enfant beige avec élastiques', prices: { DEFAULT: 10 }, id: '9.mask-fab-9' },
        ],
      },
    ],
  },
];

export default rawPricing;
